import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import mq from '../../styles/mediaQueries'

import SubServicesPageTemplate from '../../templates/sub-services-page'

import Seo from '../../components/seo'
import Layout from '../../components/Layout'
import SolidusSVG from '../../assets/logo/solidus-icon.svg'

import {
  Headline2,
  Headline3,
  Body1,
  Body2,
  Subheading2,
  Subheading1,
} from '../../components/Typography'

const SolidusLogo = props => {
  const StyledSolidus = styled(SolidusSVG)`
    width: 48px;
    height: 48px;
    display: block;
    ${mq({
      marginBottom: ['24px', '36px', '36px', '36px', '36px'],
    })};

    path {
      fill: var(--terminal-0);
    }
  `

  return <StyledSolidus {...props} />
}

const Wrapper = styled.div`
  grid-column: 1/-1;
`

const Header = styled.div`
  ${Headline2};
  color: ${props => (props.grey ? 'var(--terminal-200)' : 'var(--terminal-0)')};
`

const SubHeader = styled.div`
  ${Subheading2};
  color: var(--terminal-0);
  ${mq({
    margin: [
      '130px 0 48px 0',
      '130px 0 48px 0',
      '240px  0 120px 0',
      '240px  0 120px 0',
      '240px  0 120px 0',
    ],
    gridColumn: [
      '1 / span 4',
      '1 / span 8',
      '1 / span 8',
      '1 / span 8',
      '1 / span 8',
    ],
  })};
`

const LeftColumn = styled.div`
  ${Headline3};
  color: var(--terminal-0);
  ${mq({
    gridColumn: [
      '1 / span 4',
      '1 / span 8',
      '1 / span 4',
      '1 / span 4',
      '1 / span 4',
    ],
    margin: ['48px 0 8px 0', '48px 0 8px 0', 'unset', 'unset', 'unset'],
  })};
`

const RightColumn = styled.div`
  ${Body2};
  ${mq({
    gridColumn: [
      '1 / span 4',
      '1 / span 8',
      '5 / span 8',
      '5 / span 8',
      '5 / span 8',
    ],
    marginBottom: ['24px', '24px', 'unset', 'unset', 'unset'],
    color: [
      'var(--terminal-100)',
      'var(--terminal-100)',
      'var(--terminal-0)',
      'var(--terminal-0)',
      'var(--terminal-0)',
    ],
  })};
`

const HorizontalLine = styled.hr`
  border: 1px solid var(--terminal-300);
  margin: 60px 0;
  grid-column: 1/-1;
  ${mq({
    display: ['none', 'none', 'block', 'block', 'block'],
  })};
`

const CenteredSubHeader = styled.div`
  ${Subheading1}
  color: var(--terminal-0);
  ${mq({
    margin: [
      '204px 0 120px 0',
      '204px 0 120px 0',
      '220px  0 120px 0',
      '220px  0 120px 0',
      '220px  0 120px 0',
    ],
    gridColumnStart: ['1', '1', '1', '3', '3'],
    gridColumnEnd: ['5', '9', '13', '11', '11'],
  })};

  a {
    color: var(--screen-300);
    text-decoration: none;
  }
`

const Spacer = styled.div`
  height: 200px;
`

const OrangeGatsbyLink = styled(Link)`
  ${Body1};
  color: var(--firewall-500);
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 36px;
  display: block;
`

const OrangeLink = styled.a`
  ${Body1};
  color: var(--firewall-500);
  text-decoration: none;
  font-weight: bold;
  ${mq({
    margin: [
      '12px 0 0 0',
      '12px 0 0 0',
      '60px 36px 0 0',
      '60px 36px 0 0',
      '60px 36px 0 0',
    ],
    display: ['block', 'block', 'inline-block', 'inline-block', 'inline-block'],
  })};
`

function Solidus() {
  const pageTitle = 'Solidus'

  return (
    <SubServicesPageTemplate title={pageTitle} copyrightYear={'2022'}>
      <Seo
        title={pageTitle}
        description="Leverage a high-volume eCommerce platform that gives you total control."
        keywords={[
          `Ruby on Rails`,
          `Rails`,
          `React`,
          `Spree`,
          `Solidus`,
          `eCommerce`,
        ]}
      />
      <Layout>
        <Wrapper>
          <Spacer />
          <OrangeGatsbyLink to="/services">← Back to services</OrangeGatsbyLink>
          <SolidusLogo />
          <Header>Solidus</Header>
          <Header grey={true}>
            Leverage a high-volume ecommerce platform that gives you total
            control.
          </Header>
        </Wrapper>
        <SubHeader>
          Solidus is our home base. We were in the room at its inception and
          remain a part of the core team that guides its development. This open
          source platform is built for customization and is used by some of the
          internet’s biggest storefronts.
        </SubHeader>
        <LeftColumn>Super flexible</LeftColumn>
        <RightColumn>
          If you want to deliver a highly customized ecommerce experience, have
          unique business requirements, or are wildly ambitious with your
          company's vision, Solidus is likely the platform for you. Like its
          predecessor Spree, Solidus is a Ruby on Rails-based ecommerce platform
          that allows for unlimited customization.
        </RightColumn>
        <HorizontalLine />
        <LeftColumn>Built for growth</LeftColumn>
        <RightColumn>
          Where hosted ecommerce platforms can compromise growth and
          flexibility, Solidus brings solutions. Built for high-volume stores
          with complex needs, it has a mature feature set, scalable framework,
          and an exciting roadmap ahead. Wherever you’re going, Solidus can take
          you there.
        </RightColumn>
        <HorizontalLine />
        <LeftColumn>Community driven</LeftColumn>
        <RightColumn>
          Solidus is an open source project led by a diverse and talented team
          of developers around the globe. We’re proudly a part of the project’s
          core team and we regularly contribute to its ongoing development
          alongside its large community of ecommerce specialists.
        </RightColumn>
        <Wrapper>
          <OrangeLink href="https://solidus.io/">
            Visit the Solidus website →
          </OrangeLink>{' '}
          <OrangeLink href="https://github.com/solidusio/solidus">
            Explore Solidus on Github →
          </OrangeLink>
        </Wrapper>
        <CenteredSubHeader>
          What we’re able to build is only restricted by your imagination. From
          custom shopping experiences to enterprise-level integrations and admin
          tools-we've seen and done it all. See some examples of{' '}
          <Link to="/work">our work</Link> and read{' '}
          <Link to="/blog">our blog</Link> for more info. When you're ready to
          get building, <Link to="/contact">let's talk</Link>.
        </CenteredSubHeader>
      </Layout>
    </SubServicesPageTemplate>
  )
}

export default Solidus
