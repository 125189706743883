import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from '@emotion/styled'
import mq from '../styles/mediaQueries'

import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import NavOverlay from '../components/NavOverlay'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'

const Wrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '2', '3', '3'],
    gridColumnEnd: ['5', '9', '12', '11', '11'],
    marginTop: ['160px', '160px', '160px', '200px', '200px'],
  })};
  ${props => (props.isOverlayOpen ? 'hidden' : 'visible')};
`

const BackgroundWrapper = styled.div`
  background: var(--terminal-500);
  height: auto;
`

const ContactFormWrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '2', '2', '2'],
    gridColumnEnd: ['5', '9', '12', '12', '12'],
  })};
`

function SubServicesPageTemplate(props) {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false)
  const [pixels, setPixels] = useState(0)
  const [contactFormRef, contactFormInView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  })

  return (
    <React.Fragment>
      <NavBar
        isOverlayOpen={isOverlayOpen}
        setIsOverlayOpen={setIsOverlayOpen}
        pixels={pixels}
        setPixels={setPixels}
        theme={'night'}
        title={props.title}
      />
      <NavOverlay
        isOverlayOpen={isOverlayOpen}
        setIsOverlayOpen={setIsOverlayOpen}
      />
      <BackgroundWrapper>
        {props.children}
        <Layout>
          <ContactFormWrapper ref={contactFormRef}>
            {contactFormInView ? <ContactForm light={true} /> : null}
          </ContactFormWrapper>
          <Wrapper>
            <Footer color={'dark'} copyrightYear={props.copyrightYear} />
          </Wrapper>
        </Layout>
      </BackgroundWrapper>
    </React.Fragment>
  )
}

export default SubServicesPageTemplate
